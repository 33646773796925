<template>
  <el-cascader
    ref="area"
    :value="value"
    :options="options"
    :props="{ expandTrigger: 'hover'}"
    @change="handleChange"
  ></el-cascader>
</template>

<script>
//     <area-cascader v-model="area"></area-cascader>
// import AreaCascader from "@/components/AreaCascader/AreaCascader";

import area from "./area.json";
export default {
  props: ["value"],
  data() {
    return {
      options: area
    };
  },
  methods: {
    handleChange(value) {
      this.$emit("input", value);
      this.$emit("city-change", value[1]);
    }
  }
};
</script>

<style>
</style>